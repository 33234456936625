import { useState, useEffect, useContext } from 'react';
import { UserContext } from '~contexts/user';
import LocationContext from '../../locationContext';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

export const useFilters = () => {
  const { isLoadingLocation, pricingDetails } = useContext(LocationContext);
  const { user, isLoading: isLoadingUser, isLoaded: isLoadedUser } = useContext(UserContext);
  const { isAuthenticated, isLoading: isLoadingAuthentication } = useAuth0();
  const { wish } = user;
  const { wish: intakeWish } = useSelector((state) => state.intake);


  const defaultFilters = {
    price: null,
    languages: wish?.languages || intakeWish?.languages || [],
    days: wish?.timeframes || intakeWish?.timeframes || [],
    attributes: [...new Set(wish?.attributes)] || [...new Set(intakeWish?.attributes)] || [],
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [touched, setTouched] = useState(false);
  const [isInitializedLocation, setIsInitializedLocation] = useState(false);
  const [isInitializedWish, setIsInitializedWish] = useState(false);

  const updateFilters = (newFilters) => {
    setTouched(true);
    setFilters(newFilters);
  };

  const resetFilters = () => {
    setTouched(true);
    setFilters(defaultFilters);
  };

  useEffect(() => {
    if (isLoadingLocation || !isInitializedWish) {
      return;
    }

    if (!isInitializedLocation || pricingDetails.minPrice > filters.price || pricingDetails.maxPrice < filters.price) {
      setFilters({
        ...filters,
        price: wish?.maximumRate.amount || pricingDetails?.maxPrice,
      });
    }

    setIsInitializedLocation(true);
  }, [isLoadingLocation, isInitializedWish]);

  useEffect(() => {
    if(isLoadingAuthentication || isLoadingUser) {
      return;
    }

    if(!isAuthenticated) {
      setIsInitializedWish(true);
      return;
    }

    if(!isLoadedUser) {
      return;
    }

    if(!wish) {
      setIsInitializedWish(true);
      return;
    }

    setFilters({
      ...filters,
      languages: wish?.languages || [],
      days: wish?.timeframes || [],
      attributes: [...new Set(wish?.attributes)] || [],
    });
    setIsInitializedWish(true);
  }, [wish, isLoadingUser, isAuthenticated, isLoadedUser, isLoadingAuthentication]);

  return { filters, updateFilters, resetFilters, areFiltersInitialized: isInitializedLocation && isInitializedWish, filtersTouched: touched };
};
