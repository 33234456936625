import React, { useState } from 'react';
import cx from 'classnames';
import { FiChevronDown, FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { useIntl } from 'react-intl';

import Dropdown from '~components/dropdown';
import { SkeletonSimple } from '~components/skeleton';

const Sort = ({ value, onChange = () => {}, order, className, isLoading }) => {
  const intl = useIntl();

  const [visible, setVisible] = useState(false);

  const handleChange = (val) => {
    setVisible(false);
    onChange(val);
  };

  return isLoading ? (
    <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__matches-sort" />
  ) : (
    <div
      className={cx('c-matches__sort', {
        [className]: !!className,
      })}
    >
      <span className="c-matches__sort-label">
        {intl.formatMessage({ id: 'containers.workers.list.sort.label', defaultMessage: 'Sort by' })}
        {':'}
      </span>
      <Dropdown.Container
        value={value.value}
        placement="bottom-end"
        className=""
        visible={visible}
        onVisibleChange={setVisible}
        optionsClassName="c-context-menu c-header__context-menu"
      >
        <Dropdown.Toggle>
          <button type="button" className="c-listing__sort-toggle" onClick={() => setVisible(!visible)}>
            <span>{value.label}</span>
            <FiChevronDown
              className={cx('c-listing__sort-chevron-down', {
                active: visible,
              })}
            />
          </button>
        </Dropdown.Toggle>
        <Dropdown.Content>
          <div className="c-vertical-nav c-context-menu__nav">
            {order.map((o) => {
              return (
                <button
                  type="button"
                  className="c-vertical-nav__item c-context-menu__nav__item c-btn-text"
                  key={`${o.value}-${o.direction}`}
                  onClick={() => handleChange(o)}
                >
                  {o.label}
                  {o.arrowDown && <FiArrowDown className="c-listing__sort-item-arrow" />}
                  {o.arrowUp && <FiArrowUp className="c-listing__sort-item-arrow" />}
                </button>
              );
            })}
          </div>
        </Dropdown.Content>
      </Dropdown.Container>
    </div>
  );
};

export default Sort;
