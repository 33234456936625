import { useState, useEffect, useContext } from 'react';
import { UserContext } from '~contexts/user';
import LocationContext from '../../locationContext';
import { useAuth0 } from '@auth0/auth0-react';

export const useFilters = () => {
  const { isLoadingLocation, pricingDetails } = useContext(LocationContext);
  const { user, isLoaded: isLoadedUser } = useContext(UserContext);
  const { isLoading: isLoadingAuthentication } = useAuth0();
  const { operations } = user;

  const defaultFilters = {
    price: null,
    languages: operations?.languages || [],
    days: operations?.timeframes || [],
    attributes: operations?.blockers || [],
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [touched, setTouched] = useState(false);
  const [isInitializedLocation, setIsInitializedLocation] = useState(false);
  const [isInitializedOperations, setIsInitializedOperations] = useState(false);


  const updateFilters = (newFilters) => {
    setTouched(true);
    setFilters(newFilters);
  };

  const resetFilters = () => {
    setTouched(true);
    setFilters(defaultFilters);
  };

  useEffect(() => {
    if (isLoadingLocation || !isInitializedOperations) {
      return;
    }

    if (!isInitializedLocation || pricingDetails.minPrice > filters.price || pricingDetails.maxPrice < filters.price) {
      setFilters({
        ...filters,
        price: operations?.minimumRate.amount || pricingDetails?.minPrice,
      });
    }

    setIsInitializedLocation(true);
  }, [isLoadingLocation, isInitializedOperations]);

  useEffect(() => {
    if(isLoadingAuthentication || !isLoadedUser) {
      return;
    }

    if(!operations) {
      setIsInitializedOperations(true);
      return;
    }
    
    setFilters({
      ...filters,
      languages: operations?.languages || [],
      days: operations?.timeframes || [],
      attributes: operations?.blockers || [],
    });
    setIsInitializedOperations(true);
  }, [operations, isLoadedUser]);

  return { filters, updateFilters, resetFilters, areFiltersInitialized: isInitializedLocation && isInitializedOperations, filtersTouched: touched };
};
