import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { FiFilter } from 'react-icons/fi';
import cx from 'classnames';

import { SkeletonSimple } from '~components/skeleton';

const FiltersButton = memo(({ isLoading, className, onClick = () => {} }) => {
  const intl = useIntl();

  return isLoading ? (
    <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__matches-filters-button" />
  ) : (
    <button type="button" className={cx('c-btn-text c-filters-button', { [className]: !!className })} onClick={onClick}>
      <FiFilter /> {intl.formatMessage({ id: 'containers.workers.mobile-filters-button', defaultMessage: 'Filters' })}
    </button>
  );
});

export default FiltersButton;
