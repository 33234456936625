import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { SkeletonSimple } from '~components/skeleton';

const ResultsCount = memo(({ count, isLoading = true }) => {
  const intl = useIntl();

  return isLoading ? (
    <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__matches-count" />
  ) : (
    <div className="c-matches__count">
      {intl.formatMessage(
        { id: 'components.listing.count', defaultMessage: '{count, plural, =0 {No results } =1 {# result} other {# results}}' },
        {
          count,
        }
      )}
    </div>
  );
});

export default ResultsCount;
