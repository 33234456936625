import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import { DAYS } from '~src/constants';

import PriceSlider from '~components/forms/price-slider';
import { Checkbox } from '~components/forms/checkbox';
import Collapsible from '~components/collapsible';
import { LanguageSelector } from '~components/forms/language-selector';
import { Availability } from '~components/forms/availability';

import LocationContext from '../../locationContext';
import Attributes from './attributes';

const DesktopFilters = ({
  values,
  selectableLanguages,
  onChangePrice = () => {},
  onChangeScope = () => {},
  onChangeItem = () => {},
}) => {
  const intl = useIntl();

  const { pricingDetails } = useContext(LocationContext);
  const { minPrice, maxPrice, currency } = pricingDetails;
  const step = maxPrice > 10000 ? 1 : 0.25;

  return (
    <div className="c-filters">
      {/* PRICE */}
      <div className="c-widget c-collapsible c-widget--price">
        <div className="l-headline--tertiary">
          {intl.formatMessage({ id: 'containers.customers.filters.price.label', defaultMessage: 'Hourly rate' })}
        </div>
        <div
          className={cx('c-matches-filter__content--price', {
            'c-matches-filter__content--price-no-euro': currency !== 'EUR',
          })}
        >
          <PriceSlider
            min={minPrice / 100}
            max={maxPrice / 100}
            step={step}
            reversed
            currency={currency}
            onChange={(value) => {
              onChangePrice(value[0]);
            }}
            defaultValues={[values.price * 0.01]}
          />
        </div>
      </div>
      {/* LANGUAGES */}
      <Collapsible.Wrapper>
        <Collapsible.Header>
          {intl.formatMessage({ id: 'containers.customers.filters.languages.label', defaultMessage: 'Languages' })}{' '}
          {values.languages.length > 0 && `(${values.languages.length})`}
        </Collapsible.Header>
        <Collapsible.Content>
          <LanguageSelector
            languages={selectableLanguages}
            value={values.languages}
            onChange={(value) => onChangeScope('languages', value)}
          />
        </Collapsible.Content>
        <Collapsible.ContentCollapsed>
          {selectableLanguages.map((item) => {
            return values.languages.indexOf(item.value) >= 0 ? (
              <Checkbox
                label={intl.formatMessage({ id: `languages.${item.value}` })}
                key={item.value}
                checked={values.languages.indexOf(item.value) >= 0}
                onChange={() => onChangeItem('languages', item.value)}
              />
            ) : null;
          })}
        </Collapsible.ContentCollapsed>
      </Collapsible.Wrapper>
      {/* DAYS */}
      <Collapsible.Wrapper>
        <Collapsible.Header>
          {intl.formatMessage({ id: 'containers.customers.filters.days.label', defaultMessage: 'Days' })}{' '}
          {values.days.length > 0 && `(${values.days.length})`}
        </Collapsible.Header>
        <Collapsible.Content>
          <Availability value={values.days} onChange={(value) => onChangeScope('days', value)} />
        </Collapsible.Content>
        <Collapsible.ContentCollapsed>
          {DAYS.map(
            (item) =>
              values.days.indexOf(item) >= 0 && (
                <Checkbox
                  label={intl.formatMessage({ id: `days.long.${item}` })}
                  key={item}
                  checked
                  onChange={() => onChangeItem('days', item)}
                />
              )
          )}
        </Collapsible.ContentCollapsed>
      </Collapsible.Wrapper>
      {/* ATTRIBUTES */}
      <Collapsible.Wrapper>
        <Collapsible.Header>
          {intl.formatMessage({ id: 'containers.customers.filters.attributes.label', defaultMessage: 'Requirements' })}{' '}
          {values.attributes.length > 0 && `(${values.attributes.length})`}
        </Collapsible.Header>
        <Collapsible.Content>
          <Attributes value={values.attributes} onChange={(value) => onChangeItem('attributes', value)} />
        </Collapsible.Content>
        <Collapsible.ContentCollapsed>
          {values.attributes.map((item) => (
            <Checkbox
              label={intl.formatMessage({ id: `containers.customers.filters.attributes.values.${item}` })}
              key={item}
              checked
              onChange={() => onChangeItem('attributes', item)}
            />
          ))}
        </Collapsible.ContentCollapsed>
      </Collapsible.Wrapper>
    </div>
  );
};

export default DesktopFilters;
