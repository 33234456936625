import React from 'react';
import cx from 'classnames';

const Pin = ({ className, profilePicture, firstName = 'X', currentUser = false }) => {
  return (
    <div
      className={cx('c-pin', {
        [className]: !!className,
        'c-pin--current-user': currentUser,
      })}
    >
      <div className="c-pin__content">
        {profilePicture && profilePicture.thumbnail ? (
          <img src={profilePicture.thumbnail} alt={firstName} key={profilePicture?.updatedAt} />
        ) : (
          <>{firstName?.charAt(0)}</>
        )}
      </div>
    </div>
  );
};

export default Pin;
