import React from 'react';
import { FaListUl } from 'react-icons/fa';
import cx from 'classnames';
import { useIntl } from 'react-intl';

import { SkeletonSimple } from '~components/skeleton';

const MapToggle = ({ isLoading, isShowingMap, disabled, onClick }) => {
  const intl = useIntl();

  return isLoading ? (
    <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-map-toggle" />
  ) : (
    <div className="map-toggle-wrapper">
      <div className="map-toggle-text">
        {isShowingMap ? (
          <>{intl.formatMessage({ id: 'containers.customers.list.show-list', defaultMessage: 'Show list' })}</>
        ) : (
          <>{intl.formatMessage({ id: 'containers.customers.list.show-map', defaultMessage: 'Show map' })}</>
        )}
      </div>
      <div className="map-toggle">
        <button
          type="button"
          onClick={onClick}
          className={cx('c-toggle-button c-toggle-button--left', {
            ['c-toggle-button--active']: !isShowingMap,
          })}
          disabled={disabled || !isShowingMap}
        >
          <FaListUl />
        </button>
        <button
          type="button"
          onClick={onClick}
          className={cx('c-toggle-button c-toggle-button--right', {
            ['c-toggle-button--active']: isShowingMap,
          })}
          disabled={disabled || isShowingMap}
        >
          <img className="c-toggle-button__image" src="/images/google-maps-icon.svg" alt="Google maps" />
        </button>
      </div>
    </div>
  );
};

export default MapToggle;
