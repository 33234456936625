import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import { DAYS } from '~src/constants';

import Modal from '~components/modal';
import PriceSlider from '~components/forms/price-slider';
import { Checkbox } from '~components/forms/checkbox';
import Collapsible from '~components/collapsible';
import { LanguageSelector } from '~components/forms/language-selector';
import { Availability } from '~components/forms/availability';

import LocationContext from '../../locationContext';
import Attributes from './attributes';

const MobileFilters = ({
  values,
  isVisible,
  selectableLanguages,
  onChangePrice = () => {},
  handleToggle = () => {},
  onSubmit = () => {},
}) => {
  const intl = useIntl();

  const { pricingDetails } = useContext(LocationContext);
  const { minPrice, maxPrice, currency } = pricingDetails;
  const step = maxPrice > 10000 ? 1 : 0.25;

  const [mobileValues, setMobileValues] = useState(values);

  const handleMobileItemChange = (scope, item) => {
    const newValues = { ...mobileValues };
    newValues.touched = true;

    mobileValues[scope].indexOf(item) < 0
      ? (newValues[scope] = [...newValues[scope], item])
      : (newValues[scope] = newValues[scope].filter((value) => value !== item));

    setMobileValues(newValues);
  };

  const handleMobileScopeChange = (scope, item) => {
    const newValues = { ...mobileValues };
    newValues.touched = true;
    newValues[scope] = item;
    setMobileValues(newValues);
  };

  useEffect(() => {
    if (isVisible) {
      setMobileValues(values);
    }
  }, [isVisible]);

  return (
    <Modal.Wrapper
      isOpened={isVisible}
      onClose={() => {
        setMobileValues(values); // clear selected filters;
        handleToggle();
      }}
      className="c-modal--matches-filters"
    >
      <Modal.Header>
        <h3 className="l-headline--tertiary">
          {intl.formatMessage({ id: 'containers.customers.filters.mobile.headline', defaultMessage: 'Filters' })}
        </h3>
      </Modal.Header>
      <Modal.Content>
        {/* PRICE */}
        <div className="c-widget c-collapsible c-widget--price c-widget--price-mobile">
          <div className="l-headline--tertiary">
            {intl.formatMessage({ id: 'containers.customers.filters.price.label', defaultMessage: 'Price' })}
          </div>
          <div
            className={cx('c-matches-filter__content--price', {
              'c-matches-filter__content--price-no-euro': currency !== 'EUR',
            })}
          >
            <PriceSlider
              min={minPrice / 100}
              max={maxPrice / 100}
              currency={currency}
              step={step}
              reversed
              onChange={(value) => {
                const newValues = onChangePrice(mobileValues, value[0], false);
                if (newValues) {
                  setMobileValues(newValues);
                }
              }}
              defaultValues={[values.price * 0.01]}
            />
          </div>
        </div>
        {/* LANGUAGES */}
        <Collapsible.Wrapper>
          <Collapsible.Header>
            {intl.formatMessage({ id: 'containers.customers.filters.languages.label', defaultMessage: 'Languages' })}{' '}
            {mobileValues.languages.length > 0 && `(${mobileValues.languages.length})`}
          </Collapsible.Header>
          <Collapsible.Content>
            <LanguageSelector
              languages={selectableLanguages}
              value={mobileValues.languages}
              onChange={(value) => handleMobileScopeChange('languages', value)}
            />
          </Collapsible.Content>
          <Collapsible.ContentCollapsed>
            {selectableLanguages.map((item) => {
              return mobileValues.languages.indexOf(item.value) >= 0 ? (
                <Checkbox
                  label={intl.formatMessage({ id: `languages.${item.value}` })}
                  key={item.value}
                  checked={mobileValues.languages.indexOf(item.value) >= 0}
                  onChange={() => handleMobileItemChange('languages', item.value)}
                />
              ) : null;
            })}
          </Collapsible.ContentCollapsed>
        </Collapsible.Wrapper>
        {/* DAYS */}
        <Collapsible.Wrapper>
          <Collapsible.Header>
            {intl.formatMessage({ id: 'containers.customers.filters.days.label', defaultMessage: 'Days' })}{' '}
            {mobileValues.days.length > 0 && `(${mobileValues.days.length})`}
          </Collapsible.Header>
          <Collapsible.Content>
            <Availability value={mobileValues.days} onChange={(value) => handleMobileScopeChange('days', value)} />
          </Collapsible.Content>
          <Collapsible.ContentCollapsed>
            {DAYS.map(
              (item) =>
                mobileValues.days.indexOf(item) >= 0 && (
                  <Checkbox
                    label={intl.formatMessage({ id: `days.long.${item}` })}
                    key={item}
                    checked
                    onChange={() => handleMobileItemChange('days', item)}
                  />
                )
            )}
          </Collapsible.ContentCollapsed>
        </Collapsible.Wrapper>
        {/* ATTRIBUTES */}
        <Collapsible.Wrapper>
          <Collapsible.Header>
            {intl.formatMessage({
              id: 'containers.customers.filters.attributes.label',
              defaultMessage: 'Requirements',
            })}{' '}
            {mobileValues.attributes.length > 0 && `(${mobileValues.attributes.length})`}
          </Collapsible.Header>
          <Collapsible.Content>
            <Attributes
              value={mobileValues.attributes}
              onChange={(value) => handleMobileItemChange('attributes', value)}
            />
          </Collapsible.Content>
          <Collapsible.ContentCollapsed>
            {mobileValues.attributes.map((item) => (
              <Checkbox
                label={intl.formatMessage({ id: `containers.customers.filters.attributes.values.${item}` })}
                key={item}
                checked
                onChange={() => handleMobileItemChange('attributes', item)}
              />
            ))}
          </Collapsible.ContentCollapsed>
        </Collapsible.Wrapper>
      </Modal.Content>
      <Modal.Footer>
        <div className="c-modal__buttons">
          <button
            type="button"
            className="c-btn c-btn--wide"
            onClick={() => {
              onSubmit(mobileValues);
            }}
          >
            {intl.formatMessage({ id: 'containers.customers.filters.mobile.filter', defaultMessage: 'Apply filters' })}
          </button>
        </div>
      </Modal.Footer>
    </Modal.Wrapper>
  );
};

export default MobileFilters;
