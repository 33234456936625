import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '~components/button';

const NoResults = () => {
  const intl = useIntl();

  return (
    <div className="c-matches__no-results">
      <FiSearch />
      <strong>
        {intl.formatMessage({ id: 'containers.customers.list.no-results.title', defaultMessage: 'No results found' })}
      </strong>
      <span>
        {intl.formatMessage({
          id: 'containers.customers.list.no-results.edit-caption',
          defaultMessage: 'Please try to change your location.',
        })}
      </span>
      <Link to="/edit-offer">
        <Button type="button" className="c-btn c-matches__subscription-text l-grey">
          {intl.formatMessage({
            id: 'containers.customers.list.no-results.edit-button',
            defaultMessage: 'Edit advertisement',
          })}
        </Button>
      </Link>
    </div>
  );
};

export default NoResults;
