import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { LocationSearch } from '~components/forms/location';

import LocationContext from './locationContext';

const LocationSearchBar = () => {
  const intl = useIntl();

  const history = useHistory();

  const { locationDetails, isLoadingLocation, onChangeLocation } = useContext(LocationContext);

  const [address, setAddress] = useState(locationDetails);

  useEffect(() => {
    if (!address) {
      setAddress(locationDetails);
    }
  }, [locationDetails]);

  const handleAddressChange = (loc) => {
    if (!loc) {
      return;
    }

    const newLocation = {
      locationId: loc.place_id,
      address: loc.address,
    };

    setAddress(newLocation);

    history.push({
      pathname: `/customers`,
      search: `?locationId=${loc.place_id}`,
      state: {
        location: newLocation,
      },
    });

    onChangeLocation(loc.place_id);
  };

  return (
    <div className="c-subheader">
      <div className="c-subheader__location-fieldset">
        <LocationSearch
          isDataLoading={isLoadingLocation}
          location={address}
          placeholder={intl.formatMessage({
            id: 'containers.customers.subheader.address',
            defaultMessage: 'Address',
          })}
          className="c-subheader__location-search c-autocomplete--focus-disabled"
          onSubmit={(loc) => handleAddressChange(loc)}
        />
      </div>
    </div>
  );
};

export default LocationSearchBar;
