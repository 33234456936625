import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';

import { SkeletonSimple } from '~components/skeleton';
import LocationContext from './locationContext';

const Header = () => {
  const intl = useIntl();
  const { locationDetails, initialSearchLocationId, isLoadingLocation } = useContext(LocationContext);
  const [city, setCity] = useState(null);

  useEffect(() => {
    if (locationDetails && locationDetails.city) {
      setCity(locationDetails.city);
      return;
    }

    if (locationDetails && locationDetails.address) {
      setCity(locationDetails.address);
    }
  }, [locationDetails]);

  return (
    <div className="c-list__title">
      <h1>
        {!city && isLoadingLocation && initialSearchLocationId ? (
          <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-hero__title-skeleton" />
        ) : city ? (
          intl.formatMessage(
            {
              id: 'containers.workers.seo.headline.city',
              defaultMessage: 'Find your cleaner in {city}',
            },
            {
              city,
            }
          )
        ) : (
          intl.formatMessage({
            id: 'containers.workers.seo.headline.default',
            defaultMessage: 'Find your cleaner',
          })
        )}
      </h1>
    </div>
  );
};

export default Header;
