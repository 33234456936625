import { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useOrder = (ORDER) => {
  const intl = useIntl();

  const translatedOrder = useMemo(() => {
    return ORDER.map((o) => ({
      ...o,
      label: intl.formatMessage({ id: `sort.${o.label}` }),
    }));
  });

  const [order, setOrderValue] = useState(translatedOrder[0]);
  const [orderTouched, setOrderTouched] = useState(false);

  const setOrder = (o) => {
    setOrderTouched(true);
    setOrderValue(o);
  };

  return [translatedOrder, order, setOrder, orderTouched];
};
