import React from 'react';
import { useIntl } from 'react-intl';

import { Checkbox } from '~components/forms/checkbox';

const Attributes = ({ value, onChange = () => {} }) => {
  const intl = useIntl();

  return (
    <div className="c-checkbox__container">
      <Checkbox
        checked={value?.indexOf('dog') >= 0}
        label={intl.formatMessage({
          id: 'containers.workers.filters.attributes.values.dog',
          defaultMessage: 'I have a dog',
        })}
        onChange={() => onChange('dog')}
        className="l-bordered"
      />
      <Checkbox
        name="attributes.cat"
        checked={value?.indexOf('cat') >= 0}
        label={intl.formatMessage({
          id: 'containers.workers.filters.attributes.values.cat',
          defaultMessage: 'I have a cat',
        })}
        onChange={() => onChange('cat')}
        className="l-bordered"
      />
      <Checkbox
        name="attributes.ironing"
        checked={value?.indexOf('ironing') >= 0}
        label={intl.formatMessage({
          id: 'containers.workers.filters.attributes.values.ironing',
          defaultMessage: 'I want cleaner to iron',
        })}
        onChange={() => onChange('ironing')}
        className="l-bordered"
      />
    </div>
  );
};

export default Attributes;
