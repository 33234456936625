import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import Button from '~components/button';

const AddOfferButton = ({ destination }) => {
  const intl = useIntl();

  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) return null;

  return (
    <div className="c-add-offer-button">
      <Link to={destination}>
        <Button className="c-btn--secondary ">
          {intl.formatMessage({
            id: 'containers.workers.add-offer-button',
            defaultMessage: 'Add your own offer',
          })}
        </Button>
      </Link>
    </div>
  );
};

export default AddOfferButton;
