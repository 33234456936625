/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef, useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { useSelector } from 'react-redux';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import { isVerified } from '~helpers/common';
import { UserContext } from '~contexts/user';

import Loader from '~components/loader';
import Pin from '~components/pin';
import { Cluster, getBounds } from '~components/map';

import ResultsItem from './results-item';

const MAX_ZOOM = 17;
const DEFAULT_ZOOM = 10;

const Marker = ({ match, locationId }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [frontRefreshed, setFrontRefreshed] = useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const referenceRef = useRef();
  const popperRef = useRef(null);

  const { styles, attributes, forceUpdate } = usePopper(referenceElement, popperElement, {
    placement: 'left',
    strategy: 'absolute',
  });

  useEffect(() => {
    if (isPopupVisible && !frontRefreshed && forceUpdate) {
      forceUpdate();
      setFrontRefreshed(true);
    }
  }, [isPopupVisible]);

  const showInvitationPopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const setPopperRef = (v) => {
    popperRef.current = v;
    setPopperElement(v);
  };
  const setReferenceRef = (v) => {
    referenceRef.current = v;
    setReferenceElement(v);
  };

  return (
    <>
      <div className="c-matches-map__marker" ref={setReferenceRef} onClick={showInvitationPopup}>
        <Pin currentUser={false} profilePicture={match.profilePicture} firstName={match.firstName} />
      </div>
      {isPopupVisible
        ? ReactDOM.createPortal(
            <>
              <div className="c-matches-map__popup">
                <div className="c-dropdown__overlay" onClick={() => setIsPopupVisible(false)} />
                <div
                  ref={setPopperRef}
                  className={cx('c-matches-map__popup-wrapper', {
                    isPopupVisible,
                  })}
                  // style={styles.offset}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  <ResultsItem
                    key={match.personId}
                    locationId={locationId}
                    personId={match.personId}
                    score={match.score}
                    price={match.price}
                    address={match.location}
                    experience={match.experience}
                    firstName={match.firstName}
                    profilePicture={match.profilePicture}
                    verification={isVerified(match.verification)}
                    lastLogin={match.lastLogin}
                    isOnline={match.isOnline}
                    personalNote={match.personalNote}
                    registerDate={match.registerDate}
                    showCloseButton
                    onClose={() => setIsPopupVisible(false)}
                  />
                </div>
              </div>
            </>,
            document.body
          )
        : null}
    </>
  );
};

const ResultsMap = ({ matches, locationId, isLoading }) => {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const { user } = useContext(UserContext);
  const googleApi = useSelector((state) => state.domainConfig.googleApi);

  const currentUserLocation =
    user && user.accountType === 'client' && user?.wish?.address?.place_id
      ? user?.wish?.address
      : user?.operations?.address?.place_id
      ? user?.operations?.address?.place_id
      : null;

  const points = matches
    .filter((i) => i.location && i.location.latitude && i.location.longitude)
    .map((i) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        match: i,
      },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(i.location.longitude), parseFloat(i.location.latitude)],
      },
    }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: MAX_ZOOM - 1 },
  });

  return (
    // Important! Always set the container height explicitly
    <div className="c-matches-map__container">
      <div className={cx('c-matches-map', { ['c-matches-map--authenticated']: !!user.accountType })}>
        {isLoading ? (
          <div className="c-matches-map__loader">
            <Loader />
          </div>
        ) : (
          <GoogleMapReact
            bootstrapURLKeys={{ key: googleApi }}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={{ lat: 52.3727598, lng: 4.8936041 }}
            defaultZoom={DEFAULT_ZOOM}
            options={{ scrollwheel: false, maxZoom: MAX_ZOOM }}
            onGoogleApiLoaded={({ map, maps }) => {
              const bounds = getBounds(maps, points, currentUserLocation);
              map.fitBounds(bounds);
              mapRef.current = map;
            }}
            onChange={({ zoom, bounds }) => {
              setZoom(zoom);
              setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
            }}
          >
            {clusters &&
              mapRef.current &&
              clusters.map((cluster) => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const { cluster: isCluster, point_count: pointCount } = cluster.properties;

                if (isCluster) {
                  return (
                    <Cluster key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                      <div
                        className="c-matches-map__cluster"
                        style={{
                          width: `${20 + (pointCount / points.length) * 20}px`,
                          height: `${20 + (pointCount / points.length) * 20}px`,
                        }}
                        onClick={() => {
                          const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                          mapRef.current.setZoom(expansionZoom);
                          mapRef.current.panTo({ lat: latitude, lng: longitude });
                        }}
                      >
                        {pointCount}
                      </div>
                    </Cluster>
                  );
                }
                return (
                  <Marker
                    key={cluster.properties.match.personId}
                    lat={latitude}
                    lng={longitude}
                    match={cluster.properties.match}
                    locationId={locationId}
                  />
                );
              })}
            {mapRef.current && currentUserLocation && currentUserLocation.longitude && currentUserLocation.latitude ? (
              <Pin
                currentUser
                profilePicture={user.profilePicture}
                firstName={user.firstName}
                lat={parseFloat(currentUserLocation?.latitude)}
                lng={parseFloat(currentUserLocation?.longitude)}
              />
            ) : null}
          </GoogleMapReact>
        )}
      </div>
    </div>
  );
};

export default ResultsMap;
