import React, { useState } from 'react';

import WorkerCard from '~components/card/worker';
import ProfileModal from '~containers/profile/modal/worker';

const ResultsItem = ({
  personId,
  locationId,
  score,
  price,
  address,
  experience,
  firstName,
  profilePicture,
  verification,
  lastLogin,
  isOnline,
  registerDate,
  personalNote,
  days,
  showFavorite,
  isFavorite,
  onFavorite = () => {},
  showCloseButton,
  onClose,
}) => {
  const [isProfileModalOpened, setIsProfileModalOpened] = useState(false);

  return (
    <>
      <WorkerCard
        personId={personId}
        locationId={locationId}
        score={score}
        price={price}
        address={address}
        experience={experience}
        firstName={firstName}
        profilePicture={profilePicture}
        verification={verification}
        lastLogin={lastLogin}
        isOnline={isOnline}
        personalNote={personalNote}
        days={days}
        showViewButton
        onView={setIsProfileModalOpened}
        showFavorite={showFavorite}
        isFavorite={isFavorite}
        onFavorite={onFavorite}
        showCloseButton={showCloseButton}
        onClose={onClose}
      />
      <ProfileModal
        personId={personId}
        locationId={locationId}
        score={score}
        price={price}
        address={address}
        experience={experience}
        firstName={firstName}
        profilePicture={profilePicture}
        verification={verification}
        lastLogin={lastLogin}
        isOnline={isOnline}
        personalNote={personalNote}
        registerDate={registerDate}
        isOpened={isProfileModalOpened}
        onToggle={setIsProfileModalOpened}
        isFavorite={isFavorite}
        onFavorite={onFavorite}
      />
    </>
  );
};

export default ResultsItem;
