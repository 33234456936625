import React, { useRef } from 'react';

import { useStateFromProp } from '~hooks/common';
import { getSelectableLanguages } from '~helpers/common';

import FiltersSkeleton from '~components/listing/filters/skeleton';

import DesktopFilters from './desktop';
import MobileFilters from './mobile';

import '~components/listing/filters/style.scss';

const Filters = ({ values, isLoading, onChange = () => {}, showMobileFilters = false, showDesktopFilters = true }) => {
  const selectableLanguages = useRef(getSelectableLanguages());
  const [mobileFiltersVisible, setMobileFiltersVisible] = useStateFromProp(showMobileFilters, setMobileFiltersVisible);

  const handleChangeItem = (oldValues, scope, item) => {
    const newValues = { ...oldValues };
    newValues.touched = true;

    oldValues[scope].indexOf(item) < 0
      ? (newValues[scope] = [...newValues[scope], item])
      : (newValues[scope] = newValues[scope].filter((value) => value !== item));

    return newValues;
  };

  const handleChangeScope = (oldValues, scope, item) => {
    const newValues = { ...oldValues };
    newValues.touched = true;
    newValues[scope] = item;

    return newValues;
  };

  const handlePriceChange = (oldValues, value, shouldDispatch = false) => {
    if (parseInt(value * 100, 10) === oldValues.price) {
      return;
    }
    const newValues = { ...oldValues };
    newValues.touched = true;
    newValues.price = parseInt(value * 100, 10);
    if (shouldDispatch) {
      onChange(newValues);
    }
    return newValues;
  };

  return isLoading ? (
    <FiltersSkeleton />
  ) : (
    <>
      {showDesktopFilters && (
        <DesktopFilters
          values={values}
          selectableLanguages={selectableLanguages.current}
          onChangePrice={(value) => handlePriceChange(values, value, true)}
          onChangeItem={(scope, item) => {
            const newValues = handleChangeItem(values, scope, item);
            onChange(newValues);
          }}
          onChangeScope={(scope, item) => {
            const newValues = handleChangeScope(values, scope, item);
            onChange(newValues);
          }}
        />
      )}

      <MobileFilters
        values={values}
        isVisible={mobileFiltersVisible}
        selectableLanguages={selectableLanguages.current}
        onChangePrice={handlePriceChange}
        onSubmit={(newValues) => {
          onChange(newValues);
          setMobileFiltersVisible(false);
        }}
        handleToggle={() => {
          setMobileFiltersVisible(false);
        }}
        onChangeItem={(scope, item) => {
          handleChangeItem(values, scope, item);
        }}
        onChangeScope={(scope, item) => {
          handleChangeScope(values, scope, item);
        }}
      />
    </>
  );
};

export default Filters;
