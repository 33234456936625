import React from 'react';
import { useIntl } from 'react-intl';
import {
  Slider,
  Handle,
  Track,
  Tick,
  Rail,
  Handles,
  Tracks,
  Ticks,
} from '~components/slider';

import './style.scss';

const PriceSlider = ({
  defaultValues,
  min = 0,
  max = 40,
  step = 0.05,
  onUpdate = () => {},
  onChange = () => {},
  currency = 'EUR',
  reversed = false,
}) => {
  const intl = useIntl();

  const domain = [min, max];

  return (
    <Slider
      mode={1}
      step={step}
      domain={domain}
      className="c-slider--price"
      onUpdate={onUpdate}
      onChange={onChange}
      values={defaultValues}
    >
      <Rail>
        {({ getRailProps }) => (
          <div className="c-slider__rail-outer" {...getRailProps()} />
        )}
      </Rail>
      <Handles>
        {({ handles, activeHandleID, getHandleProps }) => (
          <div>
            {handles.map((handle) => (
              <div key={handle.id}>
                <Handle
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                  isActive={handle.id === activeHandleID}
                >
                  <div className="c-tooltip">
                    {intl.formatNumber(handle.value, {
                      style: 'currency',
                      currency,
                    })}
                  </div>
                </Handle>
              </div>
            ))}
          </div>
        )}
      </Handles>
      <Tracks right={false}>
        {({ tracks, getTrackProps }) => (
          <div>
            {tracks.map(({ id, source, target }) => (
              <Track
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
                reversed={reversed}
              />
            ))}
          </div>
        )}
      </Tracks>
      <Ticks values={[min, max]}>
        {({ ticks }) => (
          <div>
            {ticks.map((tick) => (
              <Tick
                key={tick.id}
                tick={tick}
                count={ticks.length}
                format={(d) =>
                  intl.formatNumber(d, {
                    style: 'currency',
                    currency,
                  })
                }
              />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  );
};

export default PriceSlider;
