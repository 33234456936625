import React, { memo } from 'react';

import { SkeletonSimple } from '~components/skeleton';

import './style.scss';

const CardSkeleton = memo(({ skeletonCount = 3 }) => {
  return Array(skeletonCount)
    .fill()
    .map((item, index) => <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__card" key={index} />);
});

export default CardSkeleton;
