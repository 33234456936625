import React, { useState, useEffect, useRef, useContext } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import { useAuth0 } from '@auth0/auth0-react';
import { stringToJSON } from '~helpers/common';

import { useOrder } from '~components/listing/useOrder';
import FiltersButton from '~components/listing/filters/button';
import Sort from '~components/listing/sort';
import ResultsCount from '~components/listing/results-count';
import { useListing } from '~components/listing/useListing';
import MapToggle from '~components/map/map-toggle/map-toggle';

import ServerContext from '~src/serverContext';
import { useFilters } from './filters/useFilters';
import Filters from './filters';

import ResultsList from './results-list';
import ResultsMap from './results-map';
import AddOfferButton from './add-offer';

import LocationContext from '../locationContext';
import { ORDER } from './order';

import { searchCriteria } from './searchCriteria';

import '~components/listing/style.scss';

const LISTING_ITEMS = 12;

const WorkersList = () => {
  const intl = useIntl();

  const { locationDetails, pricingDetails, isLoadingLocation, locationTouched } = useContext(LocationContext);
  
  const { isAuthenticated } = useAuth0();

  const [order, selectedOrder, setOrder, orderTouched] = useOrder(ORDER);
  const { filters, updateFilters, filtersTouched, areFiltersInitialized } = useFilters();

  const [delayedRender, setDelayedRender] = useState(false);
  const [isShowingMap, setIsShowingMap] = useState(false);
  const [isLoadedOnce, setIsLoadedOnce] = useState(false);

  const defaultCriteria = useRef(
    searchCriteria({
      location: locationDetails,
      currency: pricingDetails?.currency,
      pagination: {
        limit: LISTING_ITEMS,
        offset: 0,
      },
      order: selectedOrder,
      filters,
    })
  ).current;

  const parseResult = (r) => {
    if (!r) {
      return null;
    }

    return {
      personId: r.id,
      firstName: r.first_name,
      profilePicture: stringToJSON(r.profile_picture),
      lastLogin: r.last_login,
      registerDate: r.registered_date,
      isOnline: r.is_online,
      personalNote: r.personal_note,
      verification: [
        {
          type: 'email',
          isVerified: (r.verification.find((item) => item.type === 'EMAIL') || {}).is_verified || false,
        },
        {
          type: 'sms',
          isVerified: (r.verification.find((item) => item.type === 'SMS') || {}).is_verified || false,
        },
      ],
      score: r.score,
      price: r.price,
      location: {
        locationId: r.location.place_id,
        place_id: r.location.place_id,
        city: r.location.city,
        country: r.location.country,
        latitude: r.location.latitude,
        longitude: r.location.longitude,
      },
      experience: r.experience,
      days: r.days,
    };
  };

  const { results, count, offset, isLoadingResults, isLoadingCount, isLoadingMore, updateListing, reorder, showMore } =
    useListing({
      criteria: defaultCriteria,
      matchRequestName: 'workers',
      countRequestName: 'workers',
      matchMockFileName: 'workers/get-workers-by-criteria',
      countMockFileName: 'workers/count-workers-by-criteria',
      itemsPerPage: LISTING_ITEMS,
      parseResult,
    });

  const [desktopFiltersVisible, setDesktopFiltersVisible] = useState(true);
  const [mobileFiltersVisible, setMobileFiltersVisible] = useState(false);

  const toggleMap = () => {
    if (isShowingMap) {
      setIsShowingMap(false);
      setDesktopFiltersVisible(true);
      return;
    }

    if (!locationDetails || !locationDetails.locationId) {
      return;
    }

    setDelayedRender(true);
    setIsShowingMap(true);
    setDesktopFiltersVisible(false);
    const newCriteria = searchCriteria({
      location: locationDetails,
      currency: pricingDetails?.currency,
      order: selectedOrder,
      filters,
    });

    updateListing(newCriteria, !isAuthenticated);
    setTimeout(() => setDelayedRender(false), 0);
  };

  useEffect(() => {
    if (!orderTouched) {
      return;
    }

    const newCriteria = searchCriteria({
      ...defaultCriteria,
      location: locationDetails,
      currency: pricingDetails?.currency,
      filters,
      pagination: {
        limit: LISTING_ITEMS,
        offset: 0,
      },
      order: selectedOrder,
    });

    reorder(newCriteria);
  }, [selectedOrder]);

  useEffect(() => {
    if (!filtersTouched) {
      return;
    }

    const pagination = !isShowingMap
      ? {
          limit: LISTING_ITEMS,
          offset: 0,
        }
      : null;

    const newCriteria = searchCriteria({
      ...defaultCriteria,
      location: locationDetails,
      currency: pricingDetails?.currency,
      filters,
      order: selectedOrder,
      pagination,
    });

    updateListing(newCriteria);
  }, [filters]);

  useEffect(() => {
    if (!locationTouched || !locationDetails || !locationDetails.locationId || isLoadingLocation || !areFiltersInitialized) {
      return;
    }

    const pagination = !isShowingMap
      ? {
          limit: LISTING_ITEMS,
          offset: 0,
        }
      : null;

    const nFilters = { ...filters };
    if (filters.price === null) {
      nFilters.price = pricingDetails.maxPrice;
    }

    const newCriteria = searchCriteria({
      location: locationDetails,
      currency: pricingDetails?.currency,
      pagination,
      order: selectedOrder,
      filters: nFilters,
    });

    updateListing(newCriteria, !isAuthenticated && !isLoadedOnce);
    setIsLoadedOnce(true);
    setTimeout(() => setDelayedRender(false), 0);
  }, [isLoadingLocation, areFiltersInitialized]);

  useEffect(() => {
    if (isLoadingLocation) {
      setDelayedRender(true);
    }
  }, [isLoadingLocation]);

  return (
    <div className="c-matches__container">
      <aside
        className={cx('c-page__sidebar c-page__sidebar--matches c-page__sidebar--tablet-hidden', {
          'l-hidden': !desktopFiltersVisible,
        })}
      >
        <div className="c-sidebar__title">
          {intl.formatMessage({
            id: 'containers.workers.sidebar.title',
            defaultMessage: 'Your wishes',
          })}
        </div>
        <Filters
          values={filters}
          onChange={updateFilters}
          showMobileFilters={mobileFiltersVisible}
          showDesktopFilters={desktopFiltersVisible}
          isLoading={isLoadingLocation || delayedRender}
        />
      </aside>
      <div className={cx('c-matches__list c-page__main', { 'c-matches__list--map-view': isShowingMap })}>
        <div className="c-matches__list-header">
          <div className="c-matches__list-header-left">
            <ResultsCount count={count} isLoading={isLoadingLocation || isLoadingCount || delayedRender} />
            <FiltersButton
              className={cx({ 'c-filters-button--map-visible': isShowingMap })}
              isLoading={isLoadingLocation || isLoadingResults || delayedRender}
              onClick={() => setMobileFiltersVisible(!mobileFiltersVisible)}
            />
          </div>
          <div className="c-matches__list-header-right">
            <MapToggle isLoading={isLoadingLocation || isLoadingResults || isLoadingCount || delayedRender} 
                          isShowingMap={isShowingMap}
                          disabled={
                            !locationDetails ||
                            !locationDetails.locationId ||
                            isLoadingLocation ||
                            isLoadingResults ||
                            isLoadingCount ||
                            !count
                          }
                          onClick={toggleMap}
            />
            {(!isLoadingLocation && !count) || isShowingMap ? null : (
              <Sort
                order={order}
                value={selectedOrder}
                isLoading={isLoadingLocation || isLoadingResults || isLoadingCount || delayedRender}
                onChange={setOrder}
              />
            )}
          </div>
        </div>

        {!isShowingMap ? (
          <ResultsList
            matches={results}
            count={count}
            locationId={locationDetails?.locationId}
            isLoading={isLoadingResults || isLoadingLocation || delayedRender}
            isLoadingMore={isLoadingMore}
            onShowMore={() =>
              showMore(
                searchCriteria({
                  ...defaultCriteria,
                  location: locationDetails,
                  currency: pricingDetails?.currency,
                  filters,
                  order: selectedOrder,
                })
              )}
          />
        ) : (
          <ResultsMap
            matches={results}
            isLoading={isLoadingResults || isLoadingCount || isLoadingLocation || delayedRender}
            locationId={locationDetails?.locationId}
          />
        )}

        {count > 0 &&
        offset + LISTING_ITEMS >= count &&
        !isLoadingMore &&
        !isLoadingResults &&
        !isLoadingCount &&
        !isLoadingLocation &&
        !delayedRender ? (
          <AddOfferButton
            destination={`/intake/client${
              locationDetails?.locationId ? `?locationId=${locationDetails?.locationId}` : ''
            }&showFields=true`}
          />
        ) : null}
      </div>
    </div>
  );
};

export default WorkersList;
