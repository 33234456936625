export const Cluster = ({ children }) => children;

export const getBounds = (maps, points, currentUserPoint) => {
  const bounds = new maps.LatLngBounds();

  points.forEach((m) => {
    if(m.geometry && m.geometry.coordinates && m.geometry.coordinates[0] && m.geometry.coordinates[1]) {
      const position = new maps.LatLng(m.geometry.coordinates[1], m.geometry.coordinates[0]);
      bounds.extend(position);
    }
  });

  if (currentUserPoint && currentUserPoint.latitude && currentUserPoint.longitude) {
    const position = new maps.LatLng(currentUserPoint.latitude, currentUserPoint.longitude);
    bounds.extend(position);
  }

  return bounds;
};
