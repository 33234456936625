import React, { memo, useState } from 'react';

import CustomerCard from '~components/card/customer';
import ProfileModal from '~containers/profile/modal/customer';

const ResultsItem = memo(
  ({
    personId,
    locationId,
    interval,
    duration,
    location,
    days,
    score,
    firstName,
    profilePicture,
    verification,
    lastLogin,
    isOnline,
    registerDate,
    personalNote,
    showFavorite,
    isFavorite,
    showCloseButton,
    onClose = () => {},
  }) => {
    const [isProfileModalOpened, setIsProfileModalOpened] = useState(false);

    return (
      <>
        <CustomerCard
          key={personId}
          personId={personId}
          locationId={locationId}
          interval={interval}
          duration={duration}
          days={days}
          address={location}
          firstName={firstName}
          profilePicture={profilePicture}
          verification={verification}
          personalNote={personalNote}
          showViewButton
          onView={setIsProfileModalOpened}
          showFavorite={showFavorite}
          isFavorite={isFavorite}
          showCloseButton={showCloseButton}
          onClose={onClose}
          lastLogin={lastLogin}
          isOnline={isOnline}
        />
        <ProfileModal
          personId={personId}
          locationId={locationId}
          location={location}
          score={score}
          interval={interval}
          duration={duration}
          days={days}
          firstName={firstName}
          profilePicture={profilePicture}
          verification={verification}
          lastLogin={lastLogin}
          isOnline={isOnline}
          personalNote={personalNote}
          registerDate={registerDate}
          isOpened={isProfileModalOpened}
          onToggle={setIsProfileModalOpened}
          isFavorite={isFavorite}
        />
      </>
    );
  }
);

export default ResultsItem;
